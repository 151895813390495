import type { ContainerModuleLoader } from '@wix/thunderbolt-ioc'
import { LoginSocialBar } from './loginSocialBar'
import { LifeCycle } from '@wix/thunderbolt-symbols'

export const page: ContainerModuleLoader = (bind) => {
	bind(LifeCycle.PageWillMountHandler, LifeCycle.PageDidUnmountHandler).to(LoginSocialBar)
}

export * from './types'
export { resolveMemberDetails } from './resolveMemberDetails'
