import { LoginSocialBarFeatureProps } from '@wix/thunderbolt-components'
import { withDependencies, named } from '@wix/thunderbolt-ioc'
import {
	IPageWillMountHandler,
	IPageDidUnmountHandler,
	IPropsStore,
	PageFeatureConfigSymbol,
	Props,
	PropsMap,
} from '@wix/thunderbolt-symbols'
import { IUrlHistoryManager, UrlHistoryManagerSymbol } from 'feature-router'
import { SiteMembersApiSymbol, ISiteMembersApi } from 'feature-site-members'
import { NavigationSymbol, INavigation } from 'feature-navigation'

import type { LoginSocialBarPageConfig } from './types'
import { name } from './symbols'
import { resolveMemberDetails } from './resolveMemberDetails'

const loginSocialBarFactory = (
	{ componentIds }: LoginSocialBarPageConfig,
	siteMembersApi: ISiteMembersApi,
	propsStore: IPropsStore,
	urlHistoryManager: IUrlHistoryManager,
	{ navigateTo }: INavigation
): IPageWillMountHandler & IPageDidUnmountHandler => {
	let onLoginCallbackId: unknown
	let onMemberDetailsRefreshCallbackId: unknown

	const updateComponentProps = async () => {
		const currentPrimaryPageHref = urlHistoryManager.getFullUrlWithoutQueryParams()
		const memberDetails = await resolveMemberDetails(siteMembersApi)

		const updatePropsMap = componentIds.reduce<PropsMap>((result, compId) => {
			const props: LoginSocialBarFeatureProps = {
				currentPrimaryPageHref,
				...memberDetails,
				onLogout() {
					siteMembersApi.logout()
				},
				onLogin() {
					siteMembersApi.promptLogin()
				},
				navigateTo,
			}

			return {
				...result,
				[compId]: props,
			}
		}, {})

		propsStore.update(updatePropsMap)
	}

	return {
		async pageWillMount() {
			onLoginCallbackId = siteMembersApi.registerToUserLogin(updateComponentProps)
			onMemberDetailsRefreshCallbackId = siteMembersApi.registerToMemberDetailsRefresh(updateComponentProps)
			await updateComponentProps()
		},
		async pageDidUnmount() {
			siteMembersApi.unRegisterToUserLogin(onLoginCallbackId)
			siteMembersApi.unRegisterToMemberDetailsRefresh(onMemberDetailsRefreshCallbackId)
		},
	}
}

export const LoginSocialBar = withDependencies(
	[named(PageFeatureConfigSymbol, name), SiteMembersApiSymbol, Props, UrlHistoryManagerSymbol, NavigationSymbol],
	loginSocialBarFactory
)
